// 公共库
import { useState, useEffect, useRef } from 'react'
import { useRafInterval } from 'ahooks'
// ui
import { Message } from '@nbit/arco'
import { t } from '@lingui/macro'
import Icon from '../icon'

import styles from './index.module.css'

function StealTip({ title, content, closeStealthTip }) {
  const [progressWidth, setProgressWidth] = useState(0)
  const [isRunning, setIsRunning] = useState(true)
  // 开启定时器
  let clear = useRafInterval(() => {
    isRunning && setProgressWidth(prevCount => prevCount + 2)
  }, 100)

  useEffect(() => {
    // 清除定时器以防止内存泄漏
    return () => clear()
  }, []) // 空数组表示仅在组件挂载和卸载时执行
  useEffect(() => {
    // 进度到 100，清除定时
    if (progressWidth >= 100) {
      clear()
      closeStealthTip()
    }
  }, [progressWidth])

  const progressStyle = {
    width: `${progressWidth}%`,
  }

  return (
    <div
      onMouseEnter={() => setIsRunning(false)}
      onMouseLeave={() => setIsRunning(true)}
      style={{ pointerEvents: 'auto' }}
    >
      <div className="steal-title">{title}</div>
      <div className="steal-content">{content}</div>
      <div className="steal-progress">
        <div className="steal-line" style={progressStyle}></div>
      </div>
    </div>
  )
}

/** 带 tip 的弹窗提示 */
export function alertStealthTip(message) {
  // 关闭 tip
  const closeStealthTip = () => {
    stealthTipRef()
  }
  const stealthTipRef = Message.info({
    content: (
      <StealTip
        title={message?.title || t`features_home_component_record_tabs_index_pil0u_f8b9`}
        content={
          message?.content ||
          t`features_home_component_record_tabs_index__whykymamr` +
            t`features_home_component_record_tabs_index_fghkbzokp9`
        }
        closeStealthTip={closeStealthTip}
      />
    ),
    // closeIcon: <Icon name="icon_delete" className="text-icon_color_01" />,
    icon: message?.icon || <Icon name="icon_hide_v2" />,
    duration: 0,
    closable: true,
    id: message?.id || 'stealthTip', // 如果多次只更新一条就使用同一个 id，如果要多条就不同且唯一的 id
    className: `${styles.stealth}`,
  })
  return {
    stealthTipRef,
    closeStealthTip,
  }
}
